* {
  overflow-x: "hidden";
  box-sizing: border-box;
}

.h1 {
  margin-bottom: 30px;
  color: #3c7ec3;
}

/* .p {
  text-align: center;
  justify-content: center;
  align-items: center;
} */

.btn {
  border-radius: 15px;
  border: 1px;
}

.table {
  width: 50%;
  margin-top: 20px;
  /* border: 1px solid black; */
}

.multicolortext {
  background-image: linear-gradient(
    to right,
    #3961c6,
    #3c7ec3,
    #eb9b88,
    #e68619
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}
