.heading2 {
  font-family: "Cormorant", serif;
  letter-spacing: 5px;
}

.textbody {
  font-family: "Cormorant", serif;
  font-size: larger;
  line-height: 1.8;
  text-align: "center";
  padding-bottom: 10px;
}

.schedule-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.confettiBtn {
  position: absolute;

  background-color: blue;
  color: "white";
  border: "none";
  border-radius: "50%";
  text-align: "center";
  width: 60px;
  height: 60px;
}

@media (min-width: 415px) {
  .confettiBtn {
    background-color: blue;
    color: "white";
    border: "none";
    border-radius: "50%";
    text-align: "center";
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 769px) {
  .confettiBtn {
    background-color: blue;
    color: "white";
    border: "none";
    border-radius: "50%";
    text-align: "center";
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 1560px) {
  .confettiBtn {
    background-color: blue;
    color: "white";
    border: "none";
    border-radius: "50%";
    text-align: "center";
    width: 100px;
    height: 100px;
  }
  .heading2 {
    font-size: xx-large;
  }
  .textbody {
    font-family: "Cormorant", serif;
    font-size: x-large;
    line-height: 1.8;
    text-align: "center";
    padding-bottom: 10px;
  }
}
