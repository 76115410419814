.blob-btn {
  /* align-self: flex-end; */
  z-index: 1;
  position: relative;
  padding: 5px 8px;
  text-align: center;
  text-transform: uppercase;
  color: #eaa659;
  background-color: transparent;
  outline: none;
  border: none;
  transition: color 0.5s;
  cursor: pointer;
  border-radius: 30px;
  height: max-content;
  /* margin-right: 20px; */
}

.blob-btn:before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #eaa659;
  border-radius: 30px;
}

.blob-btn:after {
  content: "";
  z-index: -2;
  position: absolute;
  left: 2px * 1.5;
  top: 2px * 1.5;
  width: 100%;
  height: 100%;
  transition: all 0.3s 0.2s;
  border-radius: 30px;
}

.blob-btn:hover {
  color: black;
  border-radius: 30px;
}

.blob-btn:hover:after {
  transition: all 0.3s;
  left: 0;
  top: 0;
  border-radius: 30px;
}

.blob-btn__inner {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: #ffffff;
}

.blob-btn__blobs {
  position: relative;
  display: block;
  height: 100%;
  filter: url("#goo");
}

.blob-btn__blob {
  position: absolute;
  top: 2px;
  width: calc(100% / 4);
  height: 100%;
  background: #eaa659;
  border-radius: 100%;
  transform: translate3d(0, 150%, 0) scale(1.4);
  transition: transform 0.45s;
}

@supports (filter: url("#goo")) {
  .blob-btn__blob {
    transform: translate3d(0, 150%, 0) scale(1.8);
  }
}

.blob-btn__blob:nth-child(1) {
  left: calc((1 - 1) * (120% / 4));
  transition-delay: calc((1 - 1) * 0.08s);
}

.blob-btn__blob:nth-child(2) {
  left: calc((2 - 1) * (120% / 4));
  transition-delay: calc((2 - 1) * 0.08s);
}

.blob-btn__blob:nth-child(3) {
  left: calc((3 - 1) * (120% / 4));
  transition-delay: calc((3 - 1) * 0.08s);
}

.blob-btn__blob:nth-child(4) {
  left: calc((4 - 1) * (120% / 4));
  transition-delay: calc((4 - 1) * 0.08s);
}

.blob-btn:hover .blob-btn__blob {
  transform: translateZ(0) scale(1.7);
}

@supports (filter: url("#goo")) {
  .blob-btn:hover .blob-btn__blob {
    transform: translateZ(0) scale(1.4);
  }
}
