.background-image {
  background-position: "60% 10%";
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

@media (min-width: 300px) {
  .background-image {
    background-position: 70% 10%;
    width: 100vw;
    height: 100vh;
    position: "absolute";
  }
}

@media (min-width: 821px) {
  .background-image {
    background-position: 60% 10%;
    width: 100vw;
    height: 170vh;
    position: "absolute";
  }
}

@media (min-width: 1020x) {
  .background-image {
    background-position: 60% 2%;
    width: 100vw;
    height: 180vh;
    position: "absolute";
  }
}
