@keyframes slideInLeft {
  from {
    transform: translateX(-900px);
  }
  to {
    transform: translateX(0);
  }
}

.slideInLeft {
  animation: slideInLeft 1s ease-in;
  animation-fill-mode: both;
  color: rgb(224, 29, 101);
  font-weight: 800;
  font-size: 5em;
  padding-left: 15px;
}

.pinkbutton {
  /* color: rgb(47, 26, 235); */
  color: rgb(224, 29, 101);
  background-color: transparent;
  border: none;
  font-weight: 900;
  font-size: 1.2em;
}
.tabcontainer {
  position: absolute;
  margin-top: 10px;
  bottom: 70px;
  /* position: "static"; */
}

@media (min-width: 415px) {
  .slideInLeft {
    font-weight: 800;
    font-size: 10em;
    padding-left: 50px;
  }
}

@media (min-width: 821px) {
  .slideInLeft {
    font-weight: 800;
    font-size: 15em;
    padding-left: 20px;
  }
  .tabcontainer {
    bottom: unset;
    position: "static";
    margin-top: 400px;
  }
}

@media (min-width: 1560px) {
  .slideInLeft {
    font-weight: 800;
    font-size: 20em;
    padding-left: 20px;
  }
  .pinkbutton {
    /* color: rgb(47, 26, 235); */
    color: rgb(224, 29, 101);
    background-color: transparent;
    border: none;
    font-weight: 900;
    font-size: 2.2em;
  }
  .tabcontainer {
    bottom: unset;
    position: "static";
    margin-top: 300px;
  }
}
