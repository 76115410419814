.sidebar-container {
  position: sticky;
  top: 0;
  height: calc(100vh - 10px);
  width: 20%;
  text-align: center;
  box-shadow: hwb(240 8% 25% / 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  overflow-y: auto;
  align-self: flex-start;
  padding-top: 10px;
}

.new-message {
  background-color: #3da2d7;
  padding-left: 20px;
  color: white;
  font-weight: bolder;
  font-size: medium;
}

#invisible {
  color: white;
}

.main-container {
  width: 80%;
}

.post-container {
  border-radius: 20px;
  font-family: "Cormorant", serif;
  background-color: white;
  line-height: 1.8;
  font-size: larger;
  margin: 40px;
  margin-bottom: 100px;
  overflow: hidden;
  flex-wrap: wrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.to-from {
  display: flex;
  flex-direction: column;
  background-color: rgb(185, 199, 217);
  padding: 9px;
}

.to-from-row {
  display: flex;
  flex-direction: flex-end;
  align-items: center;
  justify-content: flex-end;
}

.empty-input {
  background-color: white;
  width: 89%;
  padding-left: 10px;
  margin: 10px;
  border: 1px aliceblue solid;
}

.date {
  display: flex;
  align-self: flex-end;
  padding-right: 30px;
  padding-top: 10px;
  font-size: large;
  font-weight: 800;
}

.bottom-bar {
  background-color: rgb(185, 199, 217);
  padding-bottom: 9px;
  display: flex;
  flex-direction: row-reverse;
}

.fake-button {
  background-color: #3da2d7;
  border-radius: 45%;
  width: max-content;
  padding: 5px;
  margin: 10px;
  text-align: center;
  color: white;
  font-weight: 900;
}

.blog-text {
  padding: 40px;
}

.sidebar-text {
  padding: 20px;
}

.sidebar-text:hover {
  font-weight: 600;
}

@media (max-width: 415px) {
  .sidebar-container {
    font-size: small;
    display: none;
  }
  .main-container {
    width: 100%;
  }
  .post-container {
    margin: 10px;
    margin-bottom: 100px;
  }
}
